import styled from "styled-components";
import tw from "twin.macro";

export const StyledContent = styled.div`
  ${tw`
        w-full h-full
    `}
  overflow-x: hidden;
  background-image: url("/images/background-desktop.png");
  background-size: 100% 100%;

  @media (min-height: 768px) and (min-width: 1280px){
    ${tw`w-screen h-screen`}

    .footer {
      ${tw`absolute w-full bottom-0`}
    }
  }

  @media (max-width: 1024px) {
    ${tw`w-screen h-full`}
    background-image: url('/images/background-mobile.png');
    background-size: 100% 100%;
  }
`;

export const StyledMobileDesing = styled.div`
  @media (max-width: 1024px) {
    ${tw`block`}
  }

  @media (min-width: 1025px) {
    ${tw`hidden`}
  }

  .logo {
    ${tw`flex justify-end pr-[20px] pt-[20px]`}
  }

  .title {
    ${tw`flex justify-center flex-col mt-[20px]`}

    h1 {
      ${tw`text-center text-[#2a4b59] text-[20px] font-bold w-[320px] mx-auto`}
    }

    h2 {
      ${tw`mx-auto text-[17px] font-normal text-black`}
    }
  }

  .recuadro {
    ${tw`mt-[30px] mx-auto flex justify-center`}
  }

  .people {
    img {
      ${tw`w-full`}
    }
  }

  .carta {
    ${tw`w-[calc(100% - 20px)] mx-auto bg-white p-[10px] mt-[50px] rounded-[10px] relative`}
    box-shadow: 2px 2px 0px 0px #00000042;

    @media (max-width: 1024px) {
      ${tw`w-[calc(100% - 100px)]`}
    }

    @media (max-width: 500px) {
      ${tw`w-[calc(100% - 20px)]`}
    }

    .title-carta {
      ${tw`text-black font-normal text-[12px]`}
    }

    .inputs-container {
      ${tw`flex gap-[3px] mt-[10px]`}

      .content-input {
        &:first-child {
          ${tw`w-[65%] gap-[3px]`}
        }
      }
    }

    .brand-cart {
      ${tw`font-bold`}

      @media (max-width: 1024px) {
        ${tw`text-[14px] leading-[16.8px] mt-[10px]`}
      }
    }

    table {
      border-collapse: separate;
      border-spacing: 0px 2px;

      @media (max-width: 1024px) {
        ${tw`w-full`}
      }
    }

    table td {
    }

    .small-text {
      @media (max-width: 1024px) {
        ${tw`font-medium text-[7.5px] leading-[9px] mt-[5px]`}
      }
    }

    table {
      border-collapse: separate;

      @media (max-width: 1024px) {
        ${tw`mt-[10px]`}
      }
      .title-table {
        @media (max-width: 1024px) {
          ${tw`font-medium text-[8px] leading-[8.41px]`}
        }
      }

      .row {
        &::before {
          border-width: 1px;
        }

        border: 1px solid #808181;

        @media (max-width: 1024px) {
          ${tw`text-[8px] mt-[5px]`}
        }

        .never {
          ${tw`mx-auto h-full text-center`}
        }
      }
    }

    .button {
      ${tw`flex justify-center`}

      button {
        ${tw`bg-[#4ab8a9] rounded-[5px] h-[17px] w-[57px] text-white font-bold text-[11px] mt-[5px]`}
        box-shadow: 2px 2px 0px 0px #00000042;
      }
    }

    .text-center {
      h1 {
        ${tw`font-normal text-[11px] leading-[14.4px] text-center mt-[10px]`}
      }

      h2 {
        ${tw`text-[#4ab8a9] font-medium text-[12px] leading-[14.4px] text-center`}
      }
    }
  }
`;

export const StyledContentDesktop = styled.div`
  .title {
    ${tw`flex justify-between pt-[30px] pr-[30px] relative`}

    div {
      ${tw`w-[70%]`}
      &:first-child, &:last-child {
        ${tw`w-[15%]`}
      }

      h1 {
        ${tw`font-bold text-[30px] leading-[40px] text-[#2a4b59] text-center`}
      }

      h2 {
        ${tw`font-normal text-[30px] text-center leading-[40px] text-black`}
      }
    }
  }

  .content {
    ${tw`flex relative`}

    .col {
      ${tw`w-[60%] relative`}

      &:first-child {
        ${tw`w-[40%] pl-[70px] pt-[2%]`}
      }

      img {
        ${tw`w-[100%]`}
      }

      .carta {
        ${tw`bg-[rgba(255, 255, 255, .9)] w-[calc(100% - 40px)] mx-auto p-[10px] mt-[5%] rounded-[10px] relative`}

        .title-carta {
          ${tw`text-black font-normal text-[20px]`}
        }

        .inputs-container {
          ${tw`flex gap-[3px] mt-[10px]`}

          .content-input {
            label {
              ${tw`text-[15px]`}
            }

            &:first-child {
              ${tw`w-[65%] gap-[3px]`}
            }
          }
        }

        .brand-cart {
          ${tw`font-bold text-[20px] leading-[16.8px] mt-[10px] text-[#2a4b59]`}
        }

        table {
          ${tw`w-full mt-[10px]`}
          border-collapse: separate;
          border-spacing: 0px 5px;

          td {
            border-top: 1px solid #808181;
            border-bottom: 1px solid #808181;

            &:first-child {
              border-left: 1px solid #808181;
            }

            &:last-child {
              border-right: 1px solid #808181;
            }
          }

          &:first-child {
            border-left: 1px solid #808181;
          }

          &:last-child {
            border-right: 1px solid #808181;
          }

          .title-table {
            ${tw`font-medium text-[14px] leading-[14px] text-left pr-[8px]`}
          }

          .row {
            ${tw`text-[14px] mt-[10px]`}
            border: 1px solid #808181;

            &::before {
              border-width: 1px;
            }
          }
        }

        .small-text {
          ${tw`font-normal text-[#2a4b59] text-[14px]`}
        }

        .button {
          ${tw`flex justify-center`}

          button {
            ${tw`bg-[#4ab8a9] rounded-[5px] h-[39px] w-[129px] text-white font-bold text-[20px] mt-[5px]`}
            box-shadow: 2px 2px 0px 0px #00000042;
          }
        }

        .text-center {
          h1 {
            ${tw`font-normal text-[24px] leading-[25px] text-center mt-[10px]`}
          }

          h2 {
            ${tw`text-[#4ab8a9] font-medium text-[22px] leading-[23px] text-center`}
          }
        }
      }
    }
  }
`;
