import styled from "styled-components";
import tw from "twin.macro";

export const StyledContentCheckbox = styled.div`
  ${({ checked }) => (checked ? tw`bg-[#4ab8a9]` : tw`bg-[#fff]`)}
  border: 1px solid #4ab8a9;

  ${tw`w-[15px] h-[15px] flex justify-center items-center text-white font-bold`}

  @media (max-width: 1024px) {
    ${tw`w-[10px] h-[10px] flex justify-center items-center text-white font-bold`}
  }
`;
