import styled from "styled-components";
import tw from "twin.macro";

export const StyledInputComponent = styled.div.attrs({
    className: 'content-input'
})`
    ${tw`flex items-end`}

    label {
        ${tw`font-normal text-[10px] text-black not-italic`}
    }

    input {
        ${tw`bg-[transparent] w-[90%]`}
        border: none;
        border-bottom: 1px solid #000;

        &:focus {
            outline: none;
        }

        @media (max-width: 600px) {
            ${tw`text-[10px]`}
        }
    }
`;