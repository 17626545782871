import { useState } from "react";
import { StyledContentCheckbox } from "./checkbox.style";

const Checkbox = ({ name, setValue, value }) => {
  /** State */
  const [nameCheck, setNameCheck] = useState("false");

  return (
    <StyledContentCheckbox
      checked={value === nameCheck}
      onClick={() => {
        if (nameCheck !== value) {
          setValue(name, value);
          setNameCheck(value);
        } else {
          setValue(name, undefined);
          setNameCheck("");
        }
        
      }}
    >
      {value === nameCheck && "X"}
    </StyledContentCheckbox>
  );
};

export default Checkbox;
