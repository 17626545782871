import Footer from "./footer";
import Input from "./input";
import Checkbox from "./checkbox";

const useComponents = () => {
    return {
        Footer,
        Input,
        Checkbox
    };
}

export default useComponents;