import { Controller } from "react-hook-form";
import { StyledInputComponent } from "./input.style";

const Input = ({ label, name, control, rules }) => {
  const InputComponent = ({field}) => {
    return (
      <StyledInputComponent>
        <label>{label}</label>
        <input type="text" onChange={(e) => field.onChange(e)} value={field.value} name={field.name}/>
      </StyledInputComponent>
    );
  };

  return (
    <Controller
      render={InputComponent}
      rules={rules}
      control={control}
      name={name}
    />
  );
};

export default Input;
