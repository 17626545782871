import { StyledFooter } from "./footer.style";

const Footer = () => {
  return (
    <StyledFooter>
      <h1>
        Fuente: (1)www.ginashtma.org, 2020. (2) Brozek J, Allergic Rhinitis and
        its impact on Asthma (ARIA) guidelines- 2016 revisión; J Allergy Clin
        Immunol Volume 140, Numer 4, 950-958. (3) 1)Diana, Q., Abraham, A.,
        Yaicith, A. et al. Validation of the Spanish language version of
      </h1>
    </StyledFooter>
  );
};

export default Footer;
