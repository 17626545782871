import styled from "styled-components";
import tw from "twin.macro";

export const StyledFooter = styled.div.attrs({
  className: "footer",
  id: "footer",
})`
  ${tw`mt-[30px] mb-[0] bg-[#2a4b59] w-full px-[30px] py-[10px]`}

  @media (max-width: 1024px) {
    ${tw`mt-[auto]`}
  }

  h1 {
    ${tw`text-[14px] font-normal text-white`}

    @media (max-width: 1024px) {
      ${tw`text-[6px] font-normal text-white`}
    }
  }
`;
