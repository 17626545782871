import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  StyledContent,
  StyledContentDesktop,
  StyledMobileDesing,
} from "./App.style";
import useComponents from "./components";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const App = () => {
  const { Footer, Input, Checkbox } = useComponents();

  const { control, setValue, handleSubmit } = useForm({ mode: "onChange" });

  const [width, setWidth] = useState(window.innerWidth);
  const [doctor, setDoctor] = useState(":");

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    const params = (new URL(window.location.href)).searchParams;
    setDoctor(params.get("doctor").split("_").join(" "))
    console.log(params.get("doctor").split("_").join(" "))
  }, [window.location.pathname.split("/")[0].split("_").join(" ")]);

  const enviarDatos = (data) => {
    if (!data.name) {
      Swal.fire({
        icon: "error",
        text: "Debes ingresar tu nombre para poder identificarte",
      });
    } else if (!data.id) {
      Swal.fire({
        icon: "error",
        text: "Debes ingresar tu numero de documento para poder identificarte",
      });
    } else if (!data.nasal || !data.sneezing || !data.scratchy) {
      if (!data.nasal) {
        Swal.fire({
          icon: "error",
          text: "Debes seleccionar una respuesta para la pregunta 1.",
        });
      } else if (!data.sneezing) {
        Swal.fire({
          icon: "error",
          text: "Debes seleccionar una respuesta para la pregunta 2.",
        });
      } else if (!data.scratchy) {
        Swal.fire({
          icon: "error",
          text: "Debes seleccionar una respuesta para la pregunta 3.",
        });
      }
    } else if (!data.exiting || !data.chocking || !data.wheezing) {
      if (!data.exiting) {
        Swal.fire({
          icon: "error",
          text: "Debes seleccionar una respuesta para la pregunta 4.",
        });
      } else if (!data.chocking) {
        Swal.fire({
          icon: "error",
          text: "Debes seleccionar una respuesta para la pregunta 5.",
        });
      } else if (!data.wheezing) {
        Swal.fire({
          icon: "error",
          text: "Debes seleccionar una respuesta para la pregunta 6.",
        });
      }
    } else if (!data.chest || !data.tiredness || !data.wake_up_night) {
      if (!data.chest) {
        Swal.fire({
          icon: "error",
          text: "Debes seleccionar una respuesta para la pregunta 7.",
        });
      } else if (!data.tiredness) {
        Swal.fire({
          icon: "error",
          text: "Debes seleccionar una respuesta para la pregunta 8.",
        });
      } else if (!data.wake_up_night) {
        Swal.fire({
          icon: "error",
          text: "Debes seleccionar una respuesta para la pregunta 9.",
        });
      }
    } else if (!data.increase_medications) {
      Swal.fire({
        icon: "error",
        text: "Debes seleccionar una respuesta para la pregunta 10.",
      });
    } 
    
    else {
      data = {
        ...data,
        doctor
      }
      axios
        .post("https://api-glenmark.eml.com.co/api/create-pathology", data)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Formulario enviado",
            text: "El formulario fue enviado exitosamente a tu doctor, pronto el doctor se contactar contigo.",
          }).then((res) => {
            if (res.isConfirmed) {
              window.location.reload();
            }
          });
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Ohhh, no",
            text: "Ocurrio un problema al enviar el formulario, intenta nuevamente!",
          });
        });
    }
  };

  return (
    <Fragment>
      {width > 1025 ? (
        <App.Desktop
          setValue={setValue}
          control={control}
          Checkbox={Checkbox}
          Input={Input}
          Footer={Footer}
          enviarDatos={enviarDatos}
          handleSubmit={handleSubmit}
        />
      ) : (
        <App.Mobile
          setValue={setValue}
          control={control}
          Footer={Footer}
          Input={Input}
          Checkbox={Checkbox}
          enviarDatos={enviarDatos}
          handleSubmit={handleSubmit}
        />
      )}
    </Fragment>
  );
};

App.Desktop = ({
  setValue,
  control,
  Input,
  Checkbox,
  Footer,
  handleSubmit,
  enviarDatos,
}) => {
  return (
    <StyledContent>
      <StyledContentDesktop>
        <div className="title">
          <div></div>
          <div>
            <h1>Estamos dedicados a cuidar tu salud respiratoria…</h1>
            <h2>¡Por esto es importante conocer tu evolución!</h2>
          </div>
          <div>
            <img
              src="/images/glenmark-desktop.png"
              alt="Logo"
              width={169}
              height={80}
            />
          </div>
        </div>
        <div className="content">
          <div className="col">
            <img src="/images/items-desktop.png " alt="" />
          </div>
          <div className="col">
            <div className="carta">
              <h1 className="title-carta">
                ¡Te invitamos a diligenciar esta corta escala!
              </h1>
              <div className="inputs-container">
                <Input
                  name="name"
                  control={control}
                  rules={{}}
                  label="Nombre: "
                />
                <Input name="id" control={control} rules={{}} label="ID: " />
              </div>
              <h1 className="brand-cart">
                CARAT 10-global<sup>3</sup>
              </h1>
              <table>
                <thead>
                  <tr>
                    <th className="title-table">
                      ¿Cuántas veces tuvo en promedio durante las últimas 4
                      semanas?
                    </th>
                    <th className="title-table">Nunca</th>
                    <th className="title-table">
                      Hasta 1 o 2 días a la semana
                    </th>
                    <th className="title-table">Más de dos días a la semana</th>
                    <th className="title-table">Casi todos los días</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="row">
                    <td>1. ¿Congestión nasal?</td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="nasal"
                          value="3"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="nasal"
                          value="2"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="nasal"
                          value="1"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never" align="center">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="nasal"
                          value="0"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="row">
                    <td>2. ¿Estornudos?</td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="sneezing"
                          value="3"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="sneezing"
                          value="2"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="sneezing"
                          value="1"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never" align="center">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="sneezing"
                          value="0"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="row">
                    <td>3. ¿Rasquiña en la nariz?</td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="scratchy"
                          value="3"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="scratchy"
                          value="2"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="scratchy"
                          value="1"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never" align="center">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="scratchy"
                          value="0"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="row">
                    <td>4. ¿Salida de moco por la nariz?</td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="exiting"
                          value="3"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="exiting"
                          value="2"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="exiting"
                          value="1"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never" align="center">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="exiting"
                          value="0"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="row">
                    <td>5. ¿Falta de aire/ahogo?</td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="chocking"
                          value="3"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="chocking"
                          value="2"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="chocking"
                          value="1"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never" align="center">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="chocking"
                          value="0"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="row">
                    <td>6. ¿Silbidos en el pecho?</td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="wheezing"
                          value="3"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="wheezing"
                          value="2"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="wheezing"
                          value="1"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never" align="center">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="wheezing"
                          value="0"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="row">
                    <td>7. ¿Presión en el pecho durante un esfuerzo físico?</td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="chest"
                          value="3"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="chest"
                          value="2"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="chest"
                          value="1"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never" align="center">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="chest"
                          value="0"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="row">
                    <td>
                      8. ¿Cansancio/dificultad en realizar sus actividades
                      diarias?
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="tiredness"
                          value="3"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="tiredness"
                          value="2"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="tiredness"
                          value="1"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never" align="center">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="tiredness"
                          value="0"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className="row">
                    <td>9. ¿Se despierta por la noche?</td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="wake_up_night"
                          value="3"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="wake_up_night"
                          value="2"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="wake_up_night"
                          value="1"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never" align="center">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="wake_up_night"
                          value="0"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h1 className="small-text">
                En las últimas 4 semanas por causa de su(s) enfermedad(es)
                alérgica(s) (asma/rinitis/alergia), cuántas veces tuvo que:
              </h1>
              <table>
                <thead>
                  <tr>
                    <th
                      className="title-table"
                      style={{ visibility: "hidden" }}
                    >
                      ¿Cuántas veces tuvo en promedio durante las últimas 4
                      semanas?
                    </th>
                    <th className="title-table">
                      No estoy tomando medicamentos
                    </th>
                    <th className="title-table">Nunca</th>
                    <th className="title-table">Menos de 7 días</th>
                    <th className="title-table">7 o mas días</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="row">
                    <td>10. ¿Aumentar el uso de sus medicamentos?</td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="increase_medications"
                          value="3"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="increase_medications"
                          value="2"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="increase_medications"
                          value="1"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                    <td className="never" align="center">
                      <div className="w-[100%] flex justify-center">
                        <Checkbox
                          name="increase_medications"
                          value="0"
                          setValue={setValue}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="button" onClick={handleSubmit(enviarDatos)}>
                <button>Enviar</button>
              </div>
              <div className="text-center">
                <h1>Los resultados serán enviados a tu médico…</h1>
                <h2>¡No olvides asistir a tu cita de control!</h2>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </StyledContentDesktop>
    </StyledContent>
  );
};

App.Mobile = ({
  setValue,
  control,
  Footer,
  Input,
  Checkbox,
  handleSubmit,
  enviarDatos,
}) => {
  return (
    <StyledContent
      style={{ display: window.innerWidth > 1024 ? "hidden" : "block" }}
    >
      {/* <Footer /> */}
      <StyledMobileDesing>
        <div className="logo">
          <img src="/images/glenmark.png" alt="Logo" />
        </div>
        <div className="title">
          <h1>Estamos dedicados a cuidar tu salud respiratoria…</h1>
          <h2>¡Por esto es importante conocer tu evolución!</h2>
        </div>
        <div className="recuadro">
          <img src="/images/recuadro-mobile.png" alt="" />
        </div>

        <div className="carta">
          <h1 className="title-carta">
            ¡Te invitamos a diligenciar esta corta escala!
          </h1>
          <div className="inputs-container">
            <Input name="name" control={control} rules={{}} label="Nombre: " />
            <Input name="id" control={control} rules={{}} label="ID: " />
          </div>
          <h1 className="brand-cart">
            CARAT 10-global<sup>3</sup>
          </h1>
          <table>
            <thead>
              <tr>
                <th className="title-table">
                  ¿Cuántas veces tuvo en promedio durante las últimas 4 semanas?
                </th>
                <th className="title-table">Nunca</th>
                <th className="title-table">Hasta 1 o 2 días a la semana</th>
                <th className="title-table">Más de dos días a la semana</th>
                <th className="title-table">Casi todos los días</th>
              </tr>
            </thead>
            <tbody>
              <tr className="row">
                <td>1. ¿Congestión nasal?</td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox name="nasal" value="3" setValue={setValue} />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="nasal"
                      value="2"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="nasal"
                      value="1"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never" align="center">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="nasal"
                      value="0"
                      setValue={setValue}
                    />
                  </div>
                </td>
              </tr>
              <tr className="row">
                <td>2. ¿Estornudos?</td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="sneezing"
                      value="3"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="sneezing"
                      value="2"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="sneezing"
                      value="1"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never" align="center">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="sneezing"
                      value="0"
                      setValue={setValue}
                    />
                  </div>
                </td>
              </tr>
              <tr className="row">
                <td>3. ¿Rasquiña en la nariz?</td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="scratchy"
                      value="3"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="scratchy"
                      value="2"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="scratchy"
                      value="1"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never" align="center">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="scratchy"
                      value="0"
                      setValue={setValue}
                    />
                  </div>
                </td>
              </tr>
              <tr className="row">
                <td>4. ¿Salida de moco por la nariz?</td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="exiting"
                      value="3"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="exiting"
                      value="2"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="exiting"
                      value="1"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never" align="center">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="exiting"
                      value="0"
                      setValue={setValue}
                    />
                  </div>
                </td>
              </tr>
              <tr className="row">
                <td>5. ¿Falta de aire/ahogo?</td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="chocking"
                      value="3"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="chocking"
                      value="2"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="chocking"
                      value="1"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never" align="center">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="chocking"
                      value="0"
                      setValue={setValue}
                    />
                  </div>
                </td>
              </tr>
              <tr className="row">
                <td>6. ¿Silbidos en el pecho?</td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="wheezing"
                      value="3"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="wheezing"
                      value="2"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="wheezing"
                      value="1"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never" align="center">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="wheezing"
                      value="0"
                      setValue={setValue}
                    />
                  </div>
                </td>
              </tr>
              <tr className="row">
                <td>7. ¿Presión en el pecho durante un esfuerzo físico?</td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox name="chest" value="3" setValue={setValue} />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="chest"
                      value="2"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="chest"
                      value="1"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never" align="center">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="chest"
                      value="0"
                      setValue={setValue}
                    />
                  </div>
                </td>
              </tr>
              <tr className="row">
                <td>
                  8. ¿Cansancio/dificultad en realizar sus actividades diarias?
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="tiredness"
                      value="3"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="tiredness"
                      value="2"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="tiredness"
                      value="1"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never" align="center">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="tiredness"
                      value="0"
                      setValue={setValue}
                    />
                  </div>
                </td>
              </tr>
              <tr className="row">
                <td>9. ¿Se despierta por la noche?</td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="wake_up_night"
                      value="3"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="wake_up_night"
                      value="2"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="wake_up_night"
                      value="1"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never" align="center">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="wake_up_night"
                      value="0"
                      setValue={setValue}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <h1 className="small-text">
            En las últimas 4 semanas por causa de su(s) enfermedad(es)
            alérgica(s) (asma/rinitis/alergia), cuántas veces tuvo que:
          </h1>
          <table>
            <thead>
              <tr>
                <th className="title-table" style={{ visibility: "hidden" }}>
                  ¿Cuántas veces tuvo en promedio durante las últimas 4 semanas?
                </th>
                <th className="title-table">No estoy tomando medicamentos</th>
                <th className="title-table">Nunca</th>
                <th className="title-table">Menos de 7 días</th>
                <th className="title-table">7 o mas días</th>
              </tr>
            </thead>
            <tbody>
              <tr className="row">
                <td>10. ¿Aumentar el uso de sus medicamentos?</td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="increase_medications"
                      value="3"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="increase_medications"
                      value="2"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="increase_medications"
                      value="1"
                      setValue={setValue}
                    />
                  </div>
                </td>
                <td className="never" align="center">
                  <div className="w-[100%] flex justify-center">
                    <Checkbox
                      name="increase_medications"
                      value="0"
                      setValue={setValue}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="button">
            <button onClick={handleSubmit(enviarDatos)}>Enviar</button>
          </div>
          <div className="text-center">
            <h1>Los resultados serán enviados a tu médico…</h1>
            <h2>¡No olvides asistir a tu cita de control!</h2>
          </div>
        </div>
        <div className="people">
          <img src="/images/people.png" alt="" />
        </div>
        <Footer />
      </StyledMobileDesing>
    </StyledContent>
  );
};

export default App;
